exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amazon-style-receipts-generator-js": () => import("./../../../src/pages/amazon-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-amazon-style-receipts-generator-js" */),
  "component---src-pages-auto-repair-receipt-js": () => import("./../../../src/pages/auto-repair-receipt.js" /* webpackChunkName: "component---src-pages-auto-repair-receipt-js" */),
  "component---src-pages-best-apps-for-receipt-scanning-js": () => import("./../../../src/pages/best-apps-for-receipt-scanning.js" /* webpackChunkName: "component---src-pages-best-apps-for-receipt-scanning-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-receipts-js": () => import("./../../../src/pages/business-receipts.js" /* webpackChunkName: "component---src-pages-business-receipts-js" */),
  "component---src-pages-business-tax-receipt-js": () => import("./../../../src/pages/business-tax-receipt.js" /* webpackChunkName: "component---src-pages-business-tax-receipt-js" */),
  "component---src-pages-car-repair-receipts-js": () => import("./../../../src/pages/car-repair-receipts.js" /* webpackChunkName: "component---src-pages-car-repair-receipts-js" */),
  "component---src-pages-cash-receipts-js": () => import("./../../../src/pages/cash-receipts.js" /* webpackChunkName: "component---src-pages-cash-receipts-js" */),
  "component---src-pages-construction-receipt-js": () => import("./../../../src/pages/construction-receipt.js" /* webpackChunkName: "component---src-pages-construction-receipt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cyber-monday-saas-deals-js": () => import("./../../../src/pages/cyber-monday-saas-deals.js" /* webpackChunkName: "component---src-pages-cyber-monday-saas-deals-js" */),
  "component---src-pages-digital-receipt-js": () => import("./../../../src/pages/digital-receipt.js" /* webpackChunkName: "component---src-pages-digital-receipt-js" */),
  "component---src-pages-e-receipt-js": () => import("./../../../src/pages/e-receipt.js" /* webpackChunkName: "component---src-pages-e-receipt-js" */),
  "component---src-pages-expense-receipt-js": () => import("./../../../src/pages/expense-receipt.js" /* webpackChunkName: "component---src-pages-expense-receipt-js" */),
  "component---src-pages-gas-fuel-petrol-receipts-js": () => import("./../../../src/pages/gas-fuel-petrol-receipts.js" /* webpackChunkName: "component---src-pages-gas-fuel-petrol-receipts-js" */),
  "component---src-pages-grocery-store-receipts-js": () => import("./../../../src/pages/grocery-store-receipts.js" /* webpackChunkName: "component---src-pages-grocery-store-receipts-js" */),
  "component---src-pages-hotel-receipts-js": () => import("./../../../src/pages/hotel-receipts.js" /* webpackChunkName: "component---src-pages-hotel-receipts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-vs-receipt-js": () => import("./../../../src/pages/invoice-vs-receipt.js" /* webpackChunkName: "component---src-pages-invoice-vs-receipt-js" */),
  "component---src-pages-invoicing-process-js": () => import("./../../../src/pages/invoicing-process.js" /* webpackChunkName: "component---src-pages-invoicing-process-js" */),
  "component---src-pages-itemized-receipt-js": () => import("./../../../src/pages/itemized-receipt.js" /* webpackChunkName: "component---src-pages-itemized-receipt-js" */),
  "component---src-pages-jewelry-receipt-js": () => import("./../../../src/pages/jewelry-receipt.js" /* webpackChunkName: "component---src-pages-jewelry-receipt-js" */),
  "component---src-pages-keep-the-receipts-js": () => import("./../../../src/pages/keep-the-receipts.js" /* webpackChunkName: "component---src-pages-keep-the-receipts-js" */),
  "component---src-pages-logo-for-invoice-js": () => import("./../../../src/pages/logo-for-invoice.js" /* webpackChunkName: "component---src-pages-logo-for-invoice-js" */),
  "component---src-pages-lyft-style-receipts-generator-js": () => import("./../../../src/pages/lyft-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-lyft-style-receipts-generator-js" */),
  "component---src-pages-ola-style-receipts-generator-js": () => import("./../../../src/pages/ola-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-ola-style-receipts-generator-js" */),
  "component---src-pages-organize-receipts-js": () => import("./../../../src/pages/organize-receipts.js" /* webpackChunkName: "component---src-pages-organize-receipts-js" */),
  "component---src-pages-paper-vs-online-invoice-js": () => import("./../../../src/pages/paper-vs-online-invoice.js" /* webpackChunkName: "component---src-pages-paper-vs-online-invoice-js" */),
  "component---src-pages-parking-receipts-js": () => import("./../../../src/pages/parking-receipts.js" /* webpackChunkName: "component---src-pages-parking-receipts-js" */),
  "component---src-pages-payment-receipt-js": () => import("./../../../src/pages/payment-receipt.js" /* webpackChunkName: "component---src-pages-payment-receipt-js" */),
  "component---src-pages-phone-and-internet-receipt-js": () => import("./../../../src/pages/phone-and-internet-receipt.js" /* webpackChunkName: "component---src-pages-phone-and-internet-receipt-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-printing-receipts-js": () => import("./../../../src/pages/printing-receipts.js" /* webpackChunkName: "component---src-pages-printing-receipts-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rent-receipt-js": () => import("./../../../src/pages/rent-receipt.js" /* webpackChunkName: "component---src-pages-rent-receipt-js" */),
  "component---src-pages-restaurant-receipts-js": () => import("./../../../src/pages/restaurant-receipts.js" /* webpackChunkName: "component---src-pages-restaurant-receipts-js" */),
  "component---src-pages-sales-receipt-js": () => import("./../../../src/pages/sales-receipt.js" /* webpackChunkName: "component---src-pages-sales-receipt-js" */),
  "component---src-pages-service-receipt-js": () => import("./../../../src/pages/service-receipt.js" /* webpackChunkName: "component---src-pages-service-receipt-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-taxi-receipts-js": () => import("./../../../src/pages/taxi-receipts.js" /* webpackChunkName: "component---src-pages-taxi-receipts-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-pages-uber-style-receipts-generator-js": () => import("./../../../src/pages/uber-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-uber-style-receipts-generator-js" */),
  "component---src-pages-walgreens-style-receipts-generator-js": () => import("./../../../src/pages/walgreens-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-walgreens-style-receipts-generator-js" */),
  "component---src-pages-walmart-style-receipts-generator-js": () => import("./../../../src/pages/walmart-style-receipts-generator.js" /* webpackChunkName: "component---src-pages-walmart-style-receipts-generator-js" */)
}

