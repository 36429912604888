import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import UpperNotification from "./UpperNotification";
import {
  StyledLargeImg,
  StyledImg,
  StyledA,
  CommonColorButton,
} from "./common";
import logoWeb from "../images/Logo-receiptmakerly.svg";
import logo from "../images/favicon.png";
import arrowDown from "../images/arrow-down.svg";
import segmentlyImage from "../images/segmently-image.png";

const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 23px #635bff12;
  z-index: 999;
`;
const Navbar = styled.nav`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  padding: 15px 9%;
  @media (max-width: 1024px){
    padding: 15px 25px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    justify-content: start;
    gap: 8px;
  }
`;
const LogoContainer = styled.div`
  @media (max-width: 575px) {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
`;
const PageSection = styled.div`
  display: flex;
  justify-content: end;
  gap: 40px;
  flex-direction: row;
  align-items: center;
  @media (max-width: 575px) {
    display: none;
  }
`;
const StyledLink = styled(Link)`
  color: #132742;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    color: #635bff;
  }
  /* @media (max-width: 1380px) {
    font-size: 15px;
  } */
`;
const StyledAnchor = styled(StyledA)`
  color: #132742;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: #635bff;
  }
`;
const RotatingIcon = styled.img`
  transition: transform 0.2s ease;
`;
const DropDownHolder = styled.div`
  position: absolute;
  top: 15px;
  left: -20px;
  visibility: hidden;
  padding-top: 20px;
`;
const DropDownSection = styled.div`
  background-color: #fffbf8;
  border: 1px solid #f77b63;
  border-radius: 10px;
  opacity: 0.25;
  width: 400px;
  display: grid;
  transition: opacity 0.6s ease, transform 0.3s ease;
  @media (max-width: 575px) {
    opacity: 1;
    width: initial;
    margin-top: 15px;
  }
`;
const DropdownRow = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  gap: 22px;
  padding: 25px;
  border-top: 1px solid #efddcf;
  @media (max-width: 575px) {
    grid-template-columns: 100px 1fr;
    gap: 15px;
    padding: 15px;
  }
`;
const ProductsSection = styled.div`
  display: grid;
  grid-template-columns: auto 18px;
  gap: 3px;
  align-items: end;
  position: relative;
  font-weight: 500;
  &:hover {
    color: #f77b63;
  }
  &:hover ${RotatingIcon} {
    transform: rotate(180deg);
  }
  &:hover ${DropDownHolder} {
    visibility: visible;
  }
  &:hover ${DropDownSection} {
    opacity: 1;
    transform: translate(0px, 10px);
  }
`;
const SectionText = styled.p`
  color: #2d2d51;
  font-size: 16px;
  font-weight: ${({ title }) => (title ? `600` : `400`)};
  @media (max-width: 575px) {
    font-size: 14px;
  }
`;
const MobileView = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: grid;
    grid-template-columns: auto;
    justify-items: start;
    gap: 25px;
    transition: max-height 0.6s ease;
    overflow: hidden;
  }
`;
const MobileProductsRow = styled.div`
  padding-top: 30px;
  display: grid;
  grid-template-columns: auto 18px;
  gap: 0 10px;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
`;
const RotatingIconMobile = styled.img`
  transition: transform 0.2s ease;
  transform: ${({ showProducts }) =>
    showProducts ? `rotate(0deg)` : `rotate(-90deg)`};
`;
const ProductsSectionMob = styled.div`
  display: grid;
  gap: 20px;
  grid-column: 1 / span 2;
  transition: max-height 0.6s ease;
  overflow: hidden;
`;
const HamburgerSection = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 575px) {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    width: 26px;
    height: 26px;
  }
  @media (max-width: 398px){
    top: 85px;
  }
`;
const HamburgerStick = styled.div`
  width: 26px;
  height: 3px;
  border-radius: 6px;
  background-color: #2d2d51;
  transition: all 0.3s ease-in-out;
`;
const StickOne = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav
      ? `rotate(45deg) translate(10px, 5px)`
      : `rotate(0deg) translate(0px, 0px)`};
`;
const StickTwo = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav ? `translateX(-50px)` : `translateX(0px)`};
  background: ${({ activeMobileNav }) =>
    activeMobileNav ? `transparent` : `#2d2d51`};
  box-shadow: ${({ activeMobileNav }) =>
    activeMobileNav ? `none` : `0 2px 5px rgba(247 123 99 0.2)`};
  transition: transform 0.3s ease-in-out,
    ${({ activeMobileNav }) =>
      activeMobileNav
        ? `background 0.1s ease-in-out`
        : `background 0.4s ease-in-out`};
`;
const StickThree = styled(HamburgerStick)`
  transform: ${({ activeMobileNav }) =>
    activeMobileNav
      ? `rotate(-45deg) translate(6px, -2px)`
      : `rotate(0deg) translate(0px, 0px)`};
`;

const NavbarComp = () => {
  const [activeMobileNav, setActiveMobileNav] = useState(false);
  const [setHeight, setHeightState] = useState("0px");
  const [showProducts, setShowProducts] = useState(false);
  const [productHeight, setProductHeight] = useState("0px");
  const content = useRef(null);
  const contentProducts = useRef(null);

  const toggleMobileNav = () => {
    setActiveMobileNav(!activeMobileNav);
    setHeightState(
      activeMobileNav ? "0px" : `${content.current.scrollHeight}px`
    );
  };
  const toggleProductMob = () => {
    setShowProducts(!showProducts);
    setProductHeight(
      showProducts ? "0px" : `${contentProducts.current.scrollHeight}px`
    );
    setHeightState(
      showProducts
        ? `${content.current.scrollHeight}px`
        : `${
            parseInt(content.current.scrollHeight) +
            parseInt(contentProducts.current.scrollHeight)
          }px`
    );
  };
  const closeMobileNav = (event) => {
    event.stopPropagation();
    setProductHeight("0px");
    setShowProducts(false);
    setHeightState("0px");
    setActiveMobileNav(false);
  };

  // useEffect(() => {
  //   console.log(activeMobileNav, setHeight, showProducts, productHeight);
  // }, [activeMobileNav, setHeight, showProducts, productHeight]);

  return (
    <Header id="header">
      <UpperNotification />
      <Navbar>
        <LogoContainer>
          <StyledLink to="/">
            <StyledLargeImg src={logoWeb} alt="logo" />
            <StyledImg src={logo} alt="logo" width="35px" />
          </StyledLink>
          <HamburgerSection
          activeMobileNav={activeMobileNav}
          onClick={() => toggleMobileNav()}
        >
          <StickOne activeMobileNav={activeMobileNav} />
          <StickTwo activeMobileNav={activeMobileNav} />
          <StickThree activeMobileNav={activeMobileNav} />
        </HamburgerSection>
        </LogoContainer>
        <PageSection>
          <StyledLink to="/pricing/">Pricing</StyledLink>
          <StyledAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://receiptmakerly.com/blog/"
          >
            Blog
          </StyledAnchor>
          <StyledAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.receiptmakerly.com/auth/login"
          >
            Login
          </StyledAnchor>
          <StyledLink to="/pricing/">
            <CommonColorButton hoverBg="#5350E8">Get Started</CommonColorButton>
          </StyledLink>
        </PageSection>
        <MobileView ref={content} style={{ maxHeight: `${setHeight}` }}>
          <StyledLink to="/pricing/">Pricing</StyledLink>
          <StyledAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://receiptmakerly.com/blog/"
          >
            Blog
          </StyledAnchor>
          <StyledAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.receiptmakerly.com/auth/login"
          >
            Login
          </StyledAnchor>

          <StyledLink to="/pricing/" style={{ marginBottom: `10px` }}>
            <CommonColorButton boxShadow="none">Get Started</CommonColorButton>
          </StyledLink>
        </MobileView>
      </Navbar>
    </Header>
  );
};

export default NavbarComp;
