import React from "react";
import styled from "styled-components";
import { Script} from "gatsby";
import {
  StyledLargeImg,
  StyledImg,
  StyledLink,
  StyledA,
  CommonColorButton,
} from "./common";
import logoWeb from "../images/Logo-White-Receiptmakerly-Footer.svg";
import getStarted from "../images/get-started-bg.png";

const FooterContainer = styled.footer`
  background-color: #132742;
  display: grid;
  justify-items: center;
  margin-top: 7em;
`;
export const GetStarted = styled.div`
  position: relative;
  top: -80px;
  width: 70%;
  max-width: 1100px;
  display: grid;
  grid-template-columns: minmax(350px, 700px) auto;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 3em;
  background-image: url(${getStarted});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  border-radius: 20px;
  @media (max-width: 1024px) {
    width: calc(100% - 80px);
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr auto;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
    padding: 30px;
  }
`;
const FooterDownSection = styled.div`
  padding: 0 30px 40px;
  text-align: center;
`;
const FooterUpperMain = styled.div`
  display: grid;
  grid-template-columns: minmax(280px, 400px) 1fr;
  gap: 10%;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  padding: 0 10% 50px 10%;
  @media (max-width: 1250px) {
    grid-template-columns: 280px auto;
    gap: 30px;
    padding: 0 6% 50px;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 0 30px 30px;
  }
`;
const LeftFooterSection = styled.div`
  display: grid;
  gap: 30px;
`;
const FooterUpperRight = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 30px;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 700px) {
    grid-template-columns: auto auto;
    justify-content: start;
    gap: 40px 30px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`;
const ColumnSection = styled.div`
  display: grid;
  gap: 20px;
  max-width: 200px;
  @media (max-width: 575px) {
    gap: 16px;
  }
`;
const ColumnTitle = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  @media (max-width: 575px) {
    margin-bottom: 5px;
    font-size: 12px;
  }
`;
const FooterText = styled.p`
  color: #fff;
  font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <GetStarted>
        <div>
          <h3>Let’s get started!</h3>
          <p style={{ color: "#FFF" }}>#1 Online Receipt Maker</p>
        </div>
        <StyledA href="https://receiptmakerly.com/pricing/">
          <CommonColorButton color="#635BFF" bgColor="#FFF" hoverBg="#FFF">
            Get Started
          </CommonColorButton>
        </StyledA>
      </GetStarted>
      <FooterUpperMain>
        <LeftFooterSection>
          <div>
            <StyledA href="https://receiptmakerly.com/">
              <StyledLargeImg src={logoWeb} alt="logo" />
              <StyledImg src={logoWeb} alt="logo" />
            </StyledA>
          </div>
          <FooterText>
            #1 Online Receipt Maker make custom receipts online as well as
            generate receipts from common templates.
          </FooterText>
          {/* <FooterText>234/7, Hueston, USA</FooterText> */}
        </LeftFooterSection>
        <FooterUpperRight>
          <ColumnSection>
            <ColumnTitle>Pages</ColumnTitle>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/"
            >
              Home
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/best-apps-for-receipt-scanning/"
            >
              Best Apps for receipt scanning
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/invoice-vs-receipt/"
            >
              Invoice vs receipt
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/about/"
            >
              About
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/contact/"
            >
              Contact Us
            </StyledA>
          </ColumnSection>

          <ColumnSection>
            <ColumnTitle>Popular Templates</ColumnTitle>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/business-tax-receipt/"
            >
              Business tax receipt
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/cash-receipts/"
            >
              Cash receipts
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/rent-receipt/"
            >
              Rent receipt
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/itemized-receipt/"
            >
              Itemized receipt
            </StyledA>
          </ColumnSection>

          <ColumnSection>
            <ColumnTitle>TERMS</ColumnTitle>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/privacy-policy/"
            >
              Privacy Policy
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/terms-condition/"
            >
              Terms & Conditions
            </StyledA>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              href="https://receiptmakerly.com/sitemap/"
            >
              Sitemap
            </StyledA>
          </ColumnSection>

          <ColumnSection>
            <ColumnTitle>SOCIAL</ColumnTitle>
            <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.youtube.com/channel/UC8D4ZHxJ2rBe1HuDA9YmTOQ"
            >
              Youtube
            </StyledA>
            {/* <StyledA
              color="#FFF"
              fontSize="14px"
              fontsizemobile="12px"
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/appscom_io"
            >
              Twitter
            </StyledA> */}
          </ColumnSection>
        </FooterUpperRight>
      </FooterUpperMain>
      <FooterDownSection>
        <FooterText>
          © {new Date().getFullYear()} Receiptmakerly – All Rights Reserved |
          support@receiptmakerly.com
        </FooterText>
      </FooterDownSection>
      {/* Start of Tawk.to Script */}
    <Script id="tawk-chat">
      {`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/6590ed9f0ff6374032baa6d6/1hiv20eq3';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
        })();`}
    </Script>
    </FooterContainer>
  );
};

export default Footer;
