import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import "../styles/layout.css"
import CookieConsent from "react-cookie-consent"

export default ({ children }) => (
  <>
    <Navbar />
    <main>{children}</main>
    <Footer />
    <CookieConsent
        style={{
          background: `#132742`,
          color: `#FFFFFF`,
          fontSize: `14px`,
        }}
        location="bottom"
        buttonText="Got It"
        buttonStyle={{
          color: "white",
          background: "#635BFF",
          fontSize: "13px",
          borderRadius: `4px`,
          padding: "8px 25px",
          marginRight: `100px`,
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies on this website. By using this site you agree that we may
        store and access cookies on your device
      </CookieConsent>
  </>
)
