import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import banner from "../images/banner.svg";
import bannerMobile from "../images/banner-mobile.svg";
import darkSegmently from "../images/dark-sg.svg";
import darkSegmentlyMob from "../images/dark-mobile-sg.svg";

export const TitleH1 = styled.h1`
  font-size: 42px;
  letter-spacing: 0px;
  text-transform: capitalize;
  margin: 3.5rem 0 2rem;
  @media (max-width: 1100px) {
    margin: 3rem 0 0.6rem;
  }
  @media (max-width: 575px) {
    font-size: 32px;
    padding: 0;
  }
`;
export const MainH2 = styled.h2`
  margin-top: 1em;
`
export const TemplateH2 = ({ children, style, id }) => (
  <MainH2
    id={
      id && id !== ""
        ? id
        : children
            .toString()
            .split(" ")
            .join("-")
    }
    style={style}
  >
    {children}
  </MainH2>
)
export const TemplateH3 = ({ children, style, id }) => (
  <h3
    id={
      id && id !== ""
        ? children
            .toString()
            .split(" ")
            .join("-")
        : id
    }
    style={style}
  >
    {children}
  </h3>
)
export const TitleSection = styled.header`
  display: grid;
  padding: 0 30px 2em;
  text-align: center;
  gap: 5px;
  justify-items: center;
`;
export const TitleSubText = styled.p`
  font-size: 14px;
  max-width: 800px;
  margin-bottom: ${({ marginBottom }) => marginBottom || `3em`};
  @media (max-width: 575px){
     font-size: 13px;
  }
`;
export const ColoredSpan = styled.span`
  color: ${({ color }) => color || `#635BFF`};
`;
export const StyledLink = styled(Link)`
  color: ${({ color }) => color || `#20bead`};
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  @media (max-width: 575px) {
    font-size: ${({ fontsizemobile }) => fontsizemobile && fontsizemobile};
  }
`;
export const StyledA = styled.a`
  color: ${({ color }) => color || `#635BFF`};
  text-decoration: none;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  @media (max-width: 575px) {
    font-size: ${({ fontsizemobile }) => fontsizemobile && fontsizemobile};
  }
`;
export const CommonColorButton = styled.div`
  color: ${(props) => (props.color ? props.color : `white`)};
  font-size: ${(props) => (props.size ? props.size : `16px`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  border-radius: ${(props) => (props.radius ? props.radius : `8px`)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : `#635BFF`)};
  padding: ${(props) => (props.padding ? props.padding : `0px 25px`)};
  height: ${({ height }) => height || `55px`};
  line-height: ${({ height }) => height || `55px`};
  cursor: ${(props) => (props.cursor ? props.cursor : `pointer`)};
  white-space: nowrap;
  text-align: center;
  box-shadow: ${({ boxShadow }) => boxShadow || `0px 23px 16px #635bff29`};
  transition: ${(props) => props.effect === "effect" && `opacity 0.2s`};
  &:hover {
    background-color: ${(props) => props.hoverBg || `#5C59F0`};
  }
`;
export const CommonWhiteButton = styled.div`
  color: ${props => (props.color ? props.color : `#20BEAD`)};
  font-size: ${props => (props.size ? props.size : `14px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  border-radius: ${props => (props.radius ? props.radius : `6px`)};
  border: ${props =>
    props.color ? `1px solid ${props.color}` : `1px solid #20BEAD`};
  background-color: ${props => props.bgColor && props.bgColor};
  padding: ${props => (props.padding ? props.padding : `10px 25px`)};
  cursor: ${props => (props.cursor ? props.cursor : `pointer`)};
  white-space: nowrap;
  text-align: center;
  transition: ${props => props.effect === "effect" && `all 0.2s`};
  &:hover {
    color: ${props => (props.bgColor ? props.bgColor : `white`)};
    background-color: ${props => (props.color ? props.color : `#20BEAD`)};
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`
export const ButtonArrow = styled.div`
  overflow: hidden;
  display: flex;
  align-self: center;
  width: 0px;
  transition: width 0.2s ease;
`;
export const CommonArrowButton = styled.div`
  color: ${(props) => (props.color ? props.color : `white`)};
  font-size: ${(props) => (props.size ? props.size : `16px`)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  border-radius: ${(props) => (props.radius ? props.radius : `8px`)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : `#635BFF`)};
  padding: ${(props) => (props.padding ? props.padding : `0px 20px`)};
  height: ${({ height }) => height || `55px`};
  width: ${({ width }) => width || `190px`};
  line-height: ${({ height }) => height || `55px`};
  cursor: ${(props) => (props.cursor ? props.cursor : `pointer`)};
  box-shadow: ${({ boxShadow }) => boxShadow || `0px 23px 16px #635bff29`};
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.effect === "effect" && `opacity 0.2s`};
  &:hover {
    background-color: ${(props) => props.hoverBg || `#5C59F0`};
  }
  &:hover ${ButtonArrow} {
    width: 36px;
  }
`;
const H2Section = styled.div`
  display: grid;
  grid-template-columns: 6% 1fr;
  align-items: center;
  grid-gap: 20px 35px;
  margin-top: 12vh;
  @media (max-width: 575px) {
    grid-gap: 20px;
    padding-right: 20px;
    margin-top: 50px;
  }
`;
const TitleBar = styled.div`
  height: 2px;
  background-color: ${({ bgColor }) => bgColor || `#00D4FF`};
  border-radius: 0px 6px 6px 0;
`;
const H2Title = styled.h2`
  text-transform: capitalize;
  margin: 0;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : `750px`)};
`;
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(270px, 310px));
  grid-template-rows: ${(props) => (props.segmently ? `initial` : `1fr 1fr`)};
  justify-content: center;
  gap: 30px;
  padding: 5rem 8%;
  @media (max-width: 1400px) {
    gap: 15px;
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, minmax(270px, 310px));
    grid-template-rows: initial;
    gap: 25px;
  }
  @media (max-width: 600px) {
    grid-template-columns: minmax(270px, 310px);
    padding: 3rem 8%;
  }
`;
export const CommonCard = styled.div`
  padding: 40px 30px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: ${(props) =>
    props.segmently ? `0px 23px 26px #99B3D229` : `0px 23px 26px #d2a09929`};
  display: grid;
  grid-template-rows: ${(props) =>
    props.segmently ? `initial` : `auto 96px auto`};
  justify-items: center;
  align-content: start;
  gap: 10px 0;
  text-align: ${({ textAlign }) => textAlign || `center`};
  @media (max-width: 1400px) {
    padding: 40px 20px;
  }
  @media (max-width: 575px) {
    grid-template-rows: initial;
  }
`;
export const CardH4 = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  margin: 1em 0 10px;
  @media (max-width: 575px) {
    font-size: 15px;
    line-height: 28px;
  }
`;
export const CardTitle = styled.p`
  font-weight: 700;
  margin: 1em 0;
  line-height: 1.2;
`
export const CardP = styled.p`
  font-size: 14px;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;
export const ReviewCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(320px, 370px));
  justify-content: center;
  gap: 40px;
  padding: 5rem 9%;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(280px, 370px));
    gap: 25px;
  }
  @media (max-width: 800px) {
    grid-template-columns: minmax(280px, 370px);
    gap: 0;
  }
  @media (max-width: 575px) {
    padding: 3rem 9%;
  }
`;
export const ReviewCard = styled.div`
  padding: 40px 30px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: ${({ shadowColor }) => shadowColor || `0px 23px 26px #D2A09929`};
  display: grid;
  gap: 20px 0;
  margin-bottom: 40px;
  @media (max-width: 575px) {
    margin-bottom: 25px;
  }
`;
export const ReviewLowerSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: 20px;
`;
export const ReviewCardLowerText = styled.p`
  color: #2d2d51;
  font-size: 14px;
  font-weight: ${({ date }) => (date ? `500` : `600`)};
`;
export const ImageSection = styled.div`
  display: grid;
  grid-template-columns: minmax(350px, 690px) auto;
  gap: 30px;
  justify-content: space-evenly;
  align-items: center;
  padding: 8%;
  background-image: url(${banner});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  @media (max-width: 575px) {
    grid-template-columns: auto;
    background-image: url(${bannerMobile});
    justify-content: center;
    justify-items: center;
    padding: 40% 8%;
  }
`;
export const ImageTitleH2 = styled.h2`
  @media (max-width: 1500px) {
    margin-bottom: 0.6em;
  }
  @media (max-width: 575px) {
    text-align: center;
  }
`;
export const StyledLargeImg = styled.img`
  height: 40px;
  @media (max-width: 750px) {
    display: none;
  }
`;
export const StyledImg = styled.img`
  display: none;
  height: 35px;
  @media (max-width: 750px) {
    display: block;
  }
`;
export const ImageSectionSegmently = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 900px);
  justify-content: center;
  align-items: center;
  gap: 20px 0;
  padding: 4% 30px;
  background-image: url(${darkSegmently});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  @media (max-width: 1500px) {
    gap: 15px 0;
    padding: 2.8% 30px;
  }
  @media (max-width: 1366px) {
    gap: 10px 0;
    padding: 2% 30px;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    background-image: url(${darkSegmentlyMob});
    justify-content: center;
    justify-items: center;
    padding: 15% 25px;
  }
`;
export const ImageInputContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  justify-content: center;
  margin: 40px 0 20px;
  @media (max-width: 1500px) {
    margin: 30px 0 15px;
  }
  @media (max-width: 1366px) {
    margin: 25px 0 15px;
  }
  @media (max-width: 575px) {
    gap: 30px;
    margin: 30px 0 10px;
  }
`;
export const StoreInput = styled.input`
  border: 1px solid #bfcfe0;
  border-radius: 6px 0 0 6px;
  padding: 16px 20px;
  border-right: none;
  font-size: 16px;
  background-color: #1f2939;
  min-width: 300px;
  color: #fff;
  ::placeholder {
    color: #ffffff99;
  }
  ::-webkit-input-placeholder {
    color: #ffffff99;
  }
  :-ms-input-placeholder {
    color: #ffffff99;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 575px) {
    min-width: auto;
    font-size: 13px;
  }
`;
export const DomainInputTab = styled.div`
  position: relative;
  left: -15px;
  border: 1px solid #bfcfe0;
  border-radius: 6px;
  background-color: #3b475a;
  padding: 16px 20px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridColumns};
  align-content: center;
  justify-content: ${(props) => props.justifyContent};
  margin: ${(props) => (props.margin ? props.margin : `80px 0 100px`)};
  grid-column-gap: 40px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.imageGridColumn};
  justify-content: center;
  align-content: center;
  grid-area: ${(props) => props.alignImage};
  @media (max-width: 768px) {
    grid-template-columns: 80%;
  }
`;

const TextContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => props.textGridColumn};
  justify-content: center;
  grid-area: ${(props) => props.alignText};
  @media (max-width: 768px) {
    margin-top: 25px;
    grid-template-columns: 60%;
    text-align: center;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    padding: 0 20px;
  }
`;
const ContentH3 = styled.h3`
  margin: 30px 0;
  color: #2f394e;
`;
const ContentP = styled.p`
  margin: 15px 0;
`;
export const FaqContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1.5em;
  @media (max-width: 767px) {
    padding: 0 25px;
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
`;

export const ImageFigure = styled.figure`
  width: 100%;
  max-width: ${({ wide }) => !wide && `400px`};
  padding: 30px 0;
  margin: 0 auto;
`
export const StyledImage = styled.img`
  max-width: ${({ wide }) => wide ? `840px` : `400px`};
`
export const BlogImage = ({ src, alt, width, style, wide }) => (
  <ImageFigure wide={wide}>
    <img src={src} alt={alt} width={width || `100%`} style={style} />
  </ImageFigure>
)

export const BlogOl = styled.ol`
  display: grid;
`;
export const ContentLayout = (props) => (
  <ContentContainer
    gridColumns={props.gridColumns}
    justifyContent={props.justifyContent}
    margin={props.margin}
  >
    <ImageContainer
      alignImage={props.imageAlign}
      imageGridColumn={props.imageGridColumn}
    >
      <div>{props.image}</div>
    </ImageContainer>
    <TextContainer
      alignText={props.textAlign}
      textGridColumn={props.textGridColumn}
    >
      <div>
        {props.contentH3 && <ContentH3>{props.contentH3}</ContentH3>}
        {props.contentP1 !== "" && (
          <ContentP new={props.new}>{props.contentP1} </ContentP>
        )}
        {props.contentP2 !== "" && (
          <ContentP new={props.new}>{props.contentP2} </ContentP>
        )}
        {props.contentP3 !== "" && (
          <ContentP new={props.new}>{props.contentP3} </ContentP>
        )}
      </div>
    </TextContainer>
  </ContentContainer>
);

export const TitleH2Comp = (props) => {
  return (
    <H2Section>
      <TitleBar bgColor={props.barColor} />
      <H2Title maxWidth={props.maxWidth}>{props.content}</H2Title>
    </H2Section>
  );
};
